import { useEffect, useState } from 'react'
import { trackEvent } from '@phntms/next-gtm'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import useApi from '@/hooks/useApi'
import { useAuth } from '@/providers/Auth'
import { GtmEvent } from '@/types/gtm.types'
import {
  ApproveEnvelopeRequest,
  ApproveEnvelopeRequestWithId,
  EnvelopesListResponse,
  EnvelopeRestoreRequest,
  EnvelopeRestoreRequestWithId,
  EnvelopeDataResponse,
  PreReviewEnvelopeDataResponse,
  PublishEnvelopeRequest,
  RenameEnvelopesRequest,
  RenameEnvelopesRequestWithId,
  ReviewEnvelopeDataResponse,
  UpdateEnvelopeRequestWithId,
  OwnersResponse,
  RejectEnvelopeRequest,
  RejectEnvelopeRequestWithId,
  RequestEmailMFA,
  RequestSmsMFA,
  RequestEmailMFAWithId,
  RequestSmsMFAWithId,
  EnvelopeUnsubscribeRequest,
  EnvelopeUnsubscribeRequestWithId,
  EnvelopesListPublishedResponse,
  EnvelopesListCancelledResponse,
  EnvelopesListExpiredResponse,
  EnvelopesListSignedResponse,
  EnvelopesListPublishScheduledResponse,
  EnvelopesListRejectedResponse,
  EnvelopesListCancelledBySignerMfaErrorResponse,
  EnvelopesListExpireSoonResponse,
  EnvelopesListPendingResponse,
  EnvelopesListApprovedByMeResponse,
  EnvelopesListRejectedByMeResponse,
  EnvelopeSignatureLinkResponse,
  EnvelopeSignatureLinkRequest,
  PreReviewEnvelopeDataRequest,
  PreReviewEnvelopeDataRequestWithId,
  ReviewEnvelopeDataRequestWithId,
  ReviewEnvelopeDataRequest,
  EnvelopeUpdateResponse,
  CreateEnvelopeRequest,
  CreateEnvelopeResponse,
  UpdateEnvelopeRequest
} from '@/hooks/api/ecm/useEnvelopeApi/useEnvelopeApi.types'
import { QueryKeys } from '@/hooks/useApi/useApi.types'

const useEnvelopeApi = () => {
  const { handleApi, queryConfig } = useApi()
  const { authMetadata } = useAuth()
  const queryClient = useQueryClient()

  const useCreateEnvelope = () =>
    useMutation({
      mutationFn: (data: CreateEnvelopeRequest) => {
        return handleApi<CreateEnvelopeRequest, CreateEnvelopeResponse>(
          `/ecm/envelopes`,
          'POST',
          data
        )
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Envelopes]
        })
        trackEvent({
          event: GtmEvent.EnvelopeCreated
        })
      }
    })

  const useUpdateEnvelope = () =>
    useMutation({
      mutationFn: (data: UpdateEnvelopeRequestWithId) => {
        const { id, ...request } = data
        return handleApi<UpdateEnvelopeRequest, EnvelopeUpdateResponse>(
          `/ecm/envelopes/${id}`,
          'PUT',
          request
        )
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Envelopes]
        })
      }
    })

  const useRenameEnvelope = () =>
    useMutation({
      mutationFn: (data: RenameEnvelopesRequestWithId) => {
        const { id, ...request } = data
        return handleApi<RenameEnvelopesRequest, void>(
          `/ecm/envelopes/${id}/rename`,
          'PATCH',
          request
        )
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.VaultsList]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Envelopes]
        })
      }
    })

  const usePublishEnvelope = () =>
    useMutation({
      mutationFn: ({ id }: { id: string }) => {
        return handleApi<PublishEnvelopeRequest, void>(
          `/ecm/envelopes/${id}/publish`,
          'POST'
        )
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Envelopes]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.VaultsList]
        })
      }
    })

  const useDeleteEnvelope = () =>
    useMutation({
      mutationFn: (id: string) => {
        return handleApi<void, void>(`/ecm/envelopes/${id}`, 'DELETE')
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Envelopes]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Envelope]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.VaultsList]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.RecycleBinListEnvelopeAccounts]
        })
      }
    })

  const useCancelEnvelope = () =>
    useMutation({
      mutationFn: (id: string) => {
        return handleApi<void, void>(`/ecm/envelopes/${id}/cancel`, 'POST')
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Envelopes]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.VaultsList]
        })
      }
    })

  const useHoldEnvelope = () =>
    useMutation({
      mutationFn: (id: string) => {
        return handleApi<void, void>(`/ecm/envelopes/${id}/hold`, 'POST')
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Envelopes]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.VaultsList]
        })
      }
    })

  const useGetEnvelope = (id: string | null) => {
    return useQuery({
      queryKey: [QueryKeys.Envelope, id],
      queryFn: () =>
        handleApi<void, EnvelopeDataResponse>(
          `/ecm/envelopes/${id}`,
          'GET'
          // undefined,
          // {
          //   requireAuth: false
          // }
        ),
      ...queryConfig,
      enabled: !!id
    })
  }

  const usePreReviewEnvelope = ({
    id,
    ...data
  }: PreReviewEnvelopeDataRequestWithId) => {
    return useQuery({
      queryKey: [QueryKeys.PreReviewEnvelope],
      queryFn: () =>
        handleApi<PreReviewEnvelopeDataRequest, PreReviewEnvelopeDataResponse>(
          `/envelopes/signature-link/${id}`,
          'POST',
          data,
          {
            requireAuth: false
          }
        ),
      ...queryConfig,
      enabled: !!id && !!data?.recaptchaToken
    })
  }

  const useReviewEnvelope = (
    { id, ...data }: ReviewEnvelopeDataRequestWithId,
    enabled: boolean
  ) => {
    return useQuery({
      queryKey: [QueryKeys.ReviewEnvelope],
      queryFn: () =>
        handleApi<ReviewEnvelopeDataRequest, ReviewEnvelopeDataResponse>(
          `/envelopes/signature-link/${id}/review/v/1.0.14`,
          'POST',
          data,
          {
            requireAuth: false
          }
        ),
      ...queryConfig,
      enabled: !!id && !!data?.recaptchaToken && enabled
    })
  }

  const useRequestEmailMFA = () =>
    useMutation({
      mutationFn: ({ id, ...data }: RequestEmailMFAWithId) => {
        return handleApi<RequestEmailMFA, void>(
          `envelopes/signature-link/${id}/mfa/email`,
          'POST',
          {
            ...data,
            frontendVersion: process.env.NEXT_PUBLIC_FRONTEND_VERSION || '1.0.0'
          },
          {
            requireAuth: false,
            headers: {
              Origin: origin
            }
          }
        )
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.RequestEmailMFA]
        })
      }
    })

  const useRequestSmsMFA = () =>
    useMutation({
      mutationFn: ({ id, ...data }: RequestSmsMFAWithId) => {
        return handleApi<RequestSmsMFA, void>(
          `envelopes/signature-link/${id}/mfa/sms`,
          'POST',
          {
            ...data,
            frontendVersion: process.env.NEXT_PUBLIC_FRONTEND_VERSION || '1.0.0'
          },
          {
            requireAuth: false,
            headers: {
              Origin: origin
            }
          }
        )
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.RequestSmsMFA]
        })
      }
    })

  const useRequestWhatsAppMFA = () =>
    useMutation({
      mutationFn: ({ id, ...data }: RequestSmsMFAWithId) => {
        return handleApi<RequestSmsMFA, void>(
          `envelopes/signature-link/${id}/mfa/whatsapp`,
          'POST',
          {
            ...data,
            frontendVersion: process.env.NEXT_PUBLIC_FRONTEND_VERSION || '1.0.0'
          },
          {
            requireAuth: false,
            headers: {
              Origin: origin
            }
          }
        )
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.RequestWhatsAppMFA]
        })
      }
    })

  const useApproveEnvelope = () =>
    useMutation({
      mutationFn: (data: ApproveEnvelopeRequestWithId) => {
        const { id, ...request } = data
        return handleApi<ApproveEnvelopeRequest, void>(
          `envelopes/signature-link/${id}/approve`,
          'POST',
          request,
          {
            requireAuth: false
          }
        )
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ApproveEnvelope]
        })
      }
    })

  const useRejectEnvelope = () =>
    useMutation({
      mutationFn: (data: RejectEnvelopeRequestWithId) => {
        const { id, ...request } = data
        return handleApi<RejectEnvelopeRequest, void>(
          `envelopes/signature-link/${id}/reject`,
          'POST',
          {
            ...request,
            frontendVersion: process.env.NEXT_PUBLIC_FRONTEND_VERSION || '1.0.0'
          },
          {
            requireAuth: false
          }
        )
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.RejectEnvelope]
        })
      }
    })

  const useEnvelopeRestore = () =>
    useMutation({
      mutationFn: ({ id, ...data }: EnvelopeRestoreRequestWithId) =>
        handleApi<EnvelopeRestoreRequest, void>(
          `/ecm/envelopes/${id}/restore`,
          'POST',
          data
        ),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Envelopes]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.RecycleBinListEnvelopeAccounts]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.RecycleBinEnvelopesUserAccounts]
        })
      }
    })

  const useEnvelopeOwners = (enabled = true) => {
    return useQuery({
      queryKey: [QueryKeys.EnvelopesOwners],
      queryFn: () =>
        handleApi<void, OwnersResponse>('/ecm/envelopes/owners', 'GET'),
      ...queryConfig,
      enabled: enabled && !!authMetadata?.accessToken
    })
  }

  const useEnvelopesList = (
    pageNumber = 0,
    pageSize = 10,
    query: string | null,
    id?: string | null,
    allUsers = false,
    enabled = true
  ) => {
    const basePathUserAccount = '/ecm/envelopes/list/all/user-accounts'

    const url = allUsers
      ? '/ecm/envelopes/list/all/accounts'
      : id
        ? `${basePathUserAccount}/${id}`
        : basePathUserAccount

    const queryParams = new URLSearchParams({
      pageNumber: String(pageNumber + 1),
      pageSize: String(pageSize),
      ...(query && { search: encodeURIComponent(query) })
    }).toString()

    const queryKey = [
      QueryKeys.Envelopes,
      pageNumber,
      pageSize,
      query,
      url,
      enabled
    ]

    const [debouncedQuery, setDebouncedQuery] = useState(query)

    useEffect(() => {
      const handler = setTimeout(() => setDebouncedQuery(query), 300)
      return () => clearTimeout(handler)
    }, [query])

    const queryOptions = {
      queryKey,
      queryFn: () =>
        handleApi<void, EnvelopesListResponse>(`${url}?${queryParams}`, 'GET'),
      ...queryConfig,
      enabled:
        !!authMetadata?.accessToken &&
        (debouncedQuery === query || !query) &&
        enabled
    }

    const { data, error, isLoading, isSuccess, isError, isFetching } =
      useQuery(queryOptions)

    return {
      data,
      error,
      isLoading: isLoading || isFetching,
      isSuccess,
      isError,
      isFetching
    }
  }

  const useEnvelopesListDraftOrHold = (
    pageNumber = 0,
    pageSize = 10,
    query: string | null,
    id?: string | null,
    allUsers = false,
    enabled = true
  ) => {
    const basePathUserAccount =
      '/ecm/envelopes/list/draft-or-hold/user-accounts'

    const url = allUsers
      ? '/ecm/envelopes/list/draft-or-hold/accounts'
      : id
        ? `${basePathUserAccount}/${id}`
        : basePathUserAccount

    const queryParams = new URLSearchParams({
      pageNumber: String(pageNumber + 1),
      pageSize: String(pageSize),
      ...(query && { search: encodeURIComponent(query) })
    }).toString()

    const queryKey = [
      QueryKeys.Envelopes,
      pageNumber,
      pageSize,
      query,
      url,
      enabled
    ]

    const [debouncedQuery, setDebouncedQuery] = useState(query)

    useEffect(() => {
      const handler = setTimeout(() => setDebouncedQuery(query), 300)
      return () => clearTimeout(handler)
    }, [query])

    const queryOptions = {
      queryKey,
      queryFn: () =>
        handleApi<void, EnvelopesListResponse>(`${url}?${queryParams}`, 'GET'),
      ...queryConfig,
      enabled:
        !!authMetadata?.accessToken &&
        (debouncedQuery === query || !query) &&
        enabled
    }

    const { data, error, isLoading, isSuccess, isError, isFetching } =
      useQuery(queryOptions)

    return {
      data,
      error,
      isLoading: isLoading || isFetching,
      isSuccess,
      isError,
      isFetching
    }
  }

  const useEnvelopesListPublished = (
    pageNumber = 0,
    pageSize = 10,
    query: string | null,
    id?: string | null,
    allUsers = false,
    enabled = true
  ) => {
    const basePathUserAccount = '/ecm/envelopes/list/published/user-accounts'

    const url = allUsers
      ? '/ecm/envelopes/list/published/accounts'
      : id
        ? `${basePathUserAccount}/${id}`
        : basePathUserAccount

    const queryParams = new URLSearchParams({
      pageNumber: String(pageNumber + 1),
      pageSize: String(pageSize),
      ...(query && { search: encodeURIComponent(query) })
    }).toString()

    const queryKey = [
      QueryKeys.Envelopes,
      pageNumber,
      pageSize,
      query,
      url,
      enabled
    ]

    const [debouncedQuery, setDebouncedQuery] = useState(query)

    useEffect(() => {
      const handler = setTimeout(() => setDebouncedQuery(query), 300)
      return () => clearTimeout(handler)
    }, [query])

    const queryOptions = {
      queryKey,
      queryFn: () =>
        handleApi<void, EnvelopesListPublishedResponse>(
          `${url}?${queryParams}`,
          'GET'
        ),
      ...queryConfig,
      enabled:
        !!authMetadata?.accessToken &&
        (debouncedQuery === query || !query) &&
        enabled
    }

    const { data, error, isLoading, isSuccess, isError, isFetching } =
      useQuery(queryOptions)

    return {
      data,
      error,
      isLoading: isLoading || isFetching,
      isSuccess,
      isError,
      isFetching
    }
  }

  const useEnvelopesListCancelled = (
    pageNumber = 0,
    pageSize = 10,
    query: string | null,
    id?: string | null,
    allUsers = false,
    enabled = true
  ) => {
    const basePathUserAccount = '/ecm/envelopes/list/cancelled/user-accounts'

    const url = allUsers
      ? '/ecm/envelopes/list/cancelled/accounts'
      : id
        ? `${basePathUserAccount}/${id}`
        : basePathUserAccount

    const queryParams = new URLSearchParams({
      pageNumber: String(pageNumber + 1),
      pageSize: String(pageSize),
      ...(query && { search: encodeURIComponent(query) })
    }).toString()

    const queryKey = [
      QueryKeys.Envelopes,
      pageNumber,
      pageSize,
      query,
      url,
      enabled
    ]

    const [debouncedQuery, setDebouncedQuery] = useState(query)

    useEffect(() => {
      const handler = setTimeout(() => setDebouncedQuery(query), 300)
      return () => clearTimeout(handler)
    }, [query])

    const queryOptions = {
      queryKey,
      queryFn: () =>
        handleApi<void, EnvelopesListCancelledResponse>(
          `${url}?${queryParams}`,
          'GET'
        ),
      ...queryConfig,
      enabled:
        !!authMetadata?.accessToken &&
        (debouncedQuery === query || !query) &&
        enabled
    }

    const { data, error, isLoading, isSuccess, isError, isFetching } =
      useQuery(queryOptions)

    return {
      data,
      error,
      isLoading: isLoading || isFetching,
      isSuccess,
      isError,
      isFetching
    }
  }

  const useEnvelopesListExpired = (
    pageNumber = 0,
    pageSize = 10,
    query: string | null,
    id?: string | null,
    allUsers = false,
    enabled = true
  ) => {
    const basePathUserAccount = '/ecm/envelopes/list/expired/user-accounts'

    const url = allUsers
      ? '/ecm/envelopes/list/expired/accounts'
      : id
        ? `${basePathUserAccount}/${id}`
        : basePathUserAccount

    const queryParams = new URLSearchParams({
      pageNumber: String(pageNumber + 1),
      pageSize: String(pageSize),
      ...(query && { search: encodeURIComponent(query) })
    }).toString()

    const queryKey = [
      QueryKeys.Envelopes,
      pageNumber,
      pageSize,
      query,
      url,
      enabled
    ]

    const [debouncedQuery, setDebouncedQuery] = useState(query)

    useEffect(() => {
      const handler = setTimeout(() => setDebouncedQuery(query), 300)
      return () => clearTimeout(handler)
    }, [query])

    const queryOptions = {
      queryKey,
      queryFn: () =>
        handleApi<void, EnvelopesListExpiredResponse>(
          `${url}?${queryParams}`,
          'GET'
        ),
      ...queryConfig,
      enabled:
        !!authMetadata?.accessToken &&
        (debouncedQuery === query || !query) &&
        enabled
    }

    const { data, error, isLoading, isSuccess, isError, isFetching } =
      useQuery(queryOptions)

    return {
      data,
      error,
      isLoading: isLoading || isFetching,
      isSuccess,
      isError,
      isFetching
    }
  }

  const useEnvelopesListSigned = (
    pageNumber = 0,
    pageSize = 10,
    query: string | null,
    id?: string | null,
    allUsers = false,
    enabled = true
  ) => {
    const basePathUserAccount = '/ecm/envelopes/list/signed/user-accounts'

    const url = allUsers
      ? '/ecm/envelopes/list/signed/accounts'
      : id
        ? `${basePathUserAccount}/${id}`
        : basePathUserAccount

    const queryParams = new URLSearchParams({
      pageNumber: String(pageNumber + 1),
      pageSize: String(pageSize),
      ...(query && { search: encodeURIComponent(query) })
    }).toString()

    const queryKey = [
      QueryKeys.Envelopes,
      pageNumber,
      pageSize,
      query,
      url,
      enabled
    ]

    const [debouncedQuery, setDebouncedQuery] = useState(query)

    useEffect(() => {
      const handler = setTimeout(() => setDebouncedQuery(query), 300)
      return () => clearTimeout(handler)
    }, [query])

    const queryOptions = {
      queryKey,
      queryFn: () =>
        handleApi<void, EnvelopesListSignedResponse>(
          `${url}?${queryParams}`,
          'GET'
        ),
      ...queryConfig,
      enabled:
        !!authMetadata?.accessToken &&
        (debouncedQuery === query || !query) &&
        enabled
    }

    const { data, error, isLoading, isSuccess, isError, isFetching } =
      useQuery(queryOptions)

    return {
      data,
      error,
      isLoading: isLoading || isFetching,
      isSuccess,
      isError,
      isFetching
    }
  }

  const useEnvelopesListPublishScheduled = (
    pageNumber = 0,
    pageSize = 10,
    query: string | null,
    id?: string | null,
    allUsers = false,
    enabled = true
  ) => {
    const basePathUserAccount =
      '/ecm/envelopes/list/publish-scheduled/user-accounts'

    const url = allUsers
      ? '/ecm/envelopes/list/publish-scheduled/accounts'
      : id
        ? `${basePathUserAccount}/${id}`
        : basePathUserAccount

    const queryParams = new URLSearchParams({
      pageNumber: String(pageNumber + 1),
      pageSize: String(pageSize),
      ...(query && { search: encodeURIComponent(query) })
    }).toString()

    const queryKey = [
      QueryKeys.Envelopes,
      pageNumber,
      pageSize,
      query,
      url,
      enabled
    ]

    const [debouncedQuery, setDebouncedQuery] = useState(query)

    useEffect(() => {
      const handler = setTimeout(() => setDebouncedQuery(query), 300)
      return () => clearTimeout(handler)
    }, [query])

    const queryOptions = {
      queryKey,
      queryFn: () =>
        handleApi<void, EnvelopesListPublishScheduledResponse>(
          `${url}?${queryParams}`,
          'GET'
        ),
      ...queryConfig,
      enabled:
        !!authMetadata?.accessToken &&
        (debouncedQuery === query || !query) &&
        enabled
    }

    const { data, error, isLoading, isSuccess, isError, isFetching } =
      useQuery(queryOptions)

    return {
      data,
      error,
      isLoading: isLoading || isFetching,
      isSuccess,
      isError,
      isFetching
    }
  }

  const useEnvelopesListRejected = (
    pageNumber = 0,
    pageSize = 10,
    query: string | null,
    id?: string | null,
    allUsers = false,
    enabled = true
  ) => {
    const basePathUserAccount = '/ecm/envelopes/list/rejected/user-accounts'

    const url = allUsers
      ? '/ecm/envelopes/list/rejected/accounts'
      : id
        ? `${basePathUserAccount}/${id}`
        : basePathUserAccount

    const queryParams = new URLSearchParams({
      pageNumber: String(pageNumber + 1),
      pageSize: String(pageSize),
      ...(query && { search: encodeURIComponent(query) })
    }).toString()

    const queryKey = [
      QueryKeys.Envelopes,
      pageNumber,
      pageSize,
      query,
      url,
      enabled
    ]

    const [debouncedQuery, setDebouncedQuery] = useState(query)

    useEffect(() => {
      const handler = setTimeout(() => setDebouncedQuery(query), 300)
      return () => clearTimeout(handler)
    }, [query])

    const queryOptions = {
      queryKey,
      queryFn: () =>
        handleApi<void, EnvelopesListRejectedResponse>(
          `${url}?${queryParams}`,
          'GET'
        ),
      ...queryConfig,
      enabled:
        !!authMetadata?.accessToken &&
        (debouncedQuery === query || !query) &&
        enabled
    }

    const { data, error, isLoading, isSuccess, isError, isFetching } =
      useQuery(queryOptions)

    return {
      data,
      error,
      isLoading: isLoading || isFetching,
      isSuccess,
      isError,
      isFetching
    }
  }

  const useEnvelopesListCancelledBySignerMfaError = (
    pageNumber = 0,
    pageSize = 10,
    query: string | null,
    id?: string | null,
    allUsers = false,
    enabled = true
  ) => {
    const basePathUserAccount =
      '/ecm/envelopes/list/cancelled-by-signer-mfa-error/user-accounts'

    const url = allUsers
      ? '/ecm/envelopes/list/cancelled-by-signer-mfa-error/accounts'
      : id
        ? `${basePathUserAccount}/${id}`
        : basePathUserAccount

    const queryParams = new URLSearchParams({
      pageNumber: String(pageNumber + 1),
      pageSize: String(pageSize),
      ...(query && { search: encodeURIComponent(query) })
    }).toString()

    const queryKey = [
      QueryKeys.Envelopes,
      pageNumber,
      pageSize,
      query,
      url,
      enabled
    ]

    const [debouncedQuery, setDebouncedQuery] = useState(query)

    useEffect(() => {
      const handler = setTimeout(() => setDebouncedQuery(query), 300)
      return () => clearTimeout(handler)
    }, [query])

    const queryOptions = {
      queryKey,
      queryFn: () =>
        handleApi<void, EnvelopesListCancelledBySignerMfaErrorResponse>(
          `${url}?${queryParams}`,
          'GET'
        ),
      ...queryConfig,
      enabled:
        !!authMetadata?.accessToken &&
        (debouncedQuery === query || !query) &&
        enabled
    }

    const { data, error, isLoading, isSuccess, isError, isFetching } =
      useQuery(queryOptions)

    return {
      data,
      error,
      isLoading: isLoading || isFetching,
      isSuccess,
      isError,
      isFetching
    }
  }

  const useEnvelopesListExpireSoon = (
    pageNumber = 0,
    pageSize = 10,
    query: string | null,
    expiresAtUtc: string | null,
    id?: string | null,
    allUsers = false,
    enabled = true
  ) => {
    const basePathUserAccount = '/ecm/envelopes/list/expire-soon/user-accounts'

    const url = allUsers
      ? '/ecm/envelopes/list/expire-soon/accounts'
      : id
        ? `${basePathUserAccount}/${id}`
        : basePathUserAccount

    const queryParams = new URLSearchParams({
      pageNumber: String(pageNumber + 1),
      pageSize: String(pageSize),
      ...(query && { search: encodeURIComponent(query) }),
      ...(expiresAtUtc && { expiresAtUtc })
    }).toString()

    const queryKey = [
      QueryKeys.Envelopes,
      pageNumber,
      pageSize,
      query,
      url,
      enabled
    ]

    const [debouncedQuery, setDebouncedQuery] = useState(query)

    useEffect(() => {
      const handler = setTimeout(() => setDebouncedQuery(query), 300)
      return () => clearTimeout(handler)
    }, [query])

    const queryOptions = {
      queryKey,
      queryFn: () =>
        handleApi<void, EnvelopesListExpireSoonResponse>(
          `${url}?${queryParams}`,
          'GET'
        ),
      ...queryConfig,
      enabled:
        !!authMetadata?.accessToken &&
        (debouncedQuery === query || !query) &&
        enabled
    }

    const { data, error, isLoading, isSuccess, isError, isFetching } =
      useQuery(queryOptions)

    return {
      data,
      error,
      isLoading: isLoading || isFetching,
      isSuccess,
      isError,
      isFetching
    }
  }

  const useEnvelopesListPending = (
    pageNumber = 0,
    pageSize = 10,
    query: string | null,
    enabled = true
  ) => {
    const queryParams = new URLSearchParams({
      pageNumber: String(pageNumber + 1),
      pageSize: String(pageSize),
      ...(query && { search: encodeURIComponent(query) })
    }).toString()

    const queryKey = [QueryKeys.Envelopes, pageNumber, pageSize, query, enabled]

    const [debouncedQuery, setDebouncedQuery] = useState(query)

    useEffect(() => {
      const handler = setTimeout(() => setDebouncedQuery(query), 300)
      return () => clearTimeout(handler)
    }, [query])

    const queryOptions = {
      queryKey,
      queryFn: () =>
        handleApi<void, EnvelopesListPendingResponse>(
          `/ecm/envelopes/list/pending?${queryParams}`,
          'GET'
        ),
      ...queryConfig,
      enabled:
        !!authMetadata?.accessToken &&
        (debouncedQuery === query || !query) &&
        enabled
    }

    const { data, error, isLoading, isSuccess, isError, isFetching } =
      useQuery(queryOptions)

    return {
      data,
      error,
      isLoading: isLoading || isFetching,
      isSuccess,
      isError,
      isFetching
    }
  }

  const useEnvelopesListApprovedByMe = (
    pageNumber = 0,
    pageSize = 10,
    query: string | null,
    enabled = true
  ) => {
    const queryParams = new URLSearchParams({
      pageNumber: String(pageNumber + 1),
      pageSize: String(pageSize),
      ...(query && { search: encodeURIComponent(query) })
    }).toString()

    const queryKey = [QueryKeys.Envelopes, pageNumber, pageSize, query, enabled]

    const [debouncedQuery, setDebouncedQuery] = useState(query)

    useEffect(() => {
      const handler = setTimeout(() => setDebouncedQuery(query), 300)
      return () => clearTimeout(handler)
    }, [query])

    const queryOptions = {
      queryKey,
      queryFn: () =>
        handleApi<void, EnvelopesListApprovedByMeResponse>(
          `/ecm/envelopes/list/approved-by-me?${queryParams}`,
          'GET'
        ),
      ...queryConfig,
      enabled:
        !!authMetadata?.accessToken &&
        (debouncedQuery === query || !query) &&
        enabled
    }

    const { data, error, isLoading, isSuccess, isError, isFetching } =
      useQuery(queryOptions)

    return {
      data,
      error,
      isLoading: isLoading || isFetching,
      isSuccess,
      isError,
      isFetching
    }
  }

  const useEnvelopesListRejectedByMe = (
    pageNumber = 0,
    pageSize = 10,
    query: string | null,
    enabled = true
  ) => {
    const queryParams = new URLSearchParams({
      pageNumber: String(pageNumber + 1),
      pageSize: String(pageSize),
      ...(query && { search: encodeURIComponent(query) })
    }).toString()

    const queryKey = [QueryKeys.Envelopes, pageNumber, pageSize, query, enabled]

    const [debouncedQuery, setDebouncedQuery] = useState(query)

    useEffect(() => {
      const handler = setTimeout(() => setDebouncedQuery(query), 300)
      return () => clearTimeout(handler)
    }, [query])

    const queryOptions = {
      queryKey,
      queryFn: () =>
        handleApi<void, EnvelopesListRejectedByMeResponse>(
          `/ecm/envelopes/list/rejected-by-me?${queryParams}`,
          'GET'
        ),
      ...queryConfig,
      enabled:
        !!authMetadata?.accessToken &&
        (debouncedQuery === query || !query) &&
        enabled
    }

    const { data, error, isLoading, isSuccess, isError, isFetching } =
      useQuery(queryOptions)

    return {
      data,
      error,
      isLoading: isLoading || isFetching,
      isSuccess,
      isError,
      isFetching
    }
  }

  const useEnvelopeSignatureLink = () =>
    useMutation({
      mutationFn: ({ envelopeId, signerId }: EnvelopeSignatureLinkRequest) => {
        return handleApi<
          EnvelopeSignatureLinkRequest,
          EnvelopeSignatureLinkResponse
        >(
          `/ecm/envelopes/${envelopeId}/signers/${signerId}/signature-link`,
          'GET'
        )
      }
    })

  const useEnvelopeUnsubscribe = () =>
    useMutation({
      mutationFn: ({ id, ...data }: EnvelopeUnsubscribeRequestWithId) => {
        return handleApi<EnvelopeUnsubscribeRequest, void>(
          `/envelopes/signature-link/${id}/unsubscribe`,
          'POST',
          {
            ...data,
            frontendVersion: process.env.NEXT_PUBLIC_FRONTEND_VERSION || '1.0.0'
          }
        )
      }
    })

  const useMoveEnvelopeToVault = () =>
    useMutation({
      mutationFn: ({
        envelopeId,
        vaultId
      }: {
        envelopeId: string
        vaultId: string
      }) => {
        return handleApi<void, void>(
          `/ecm/envelopes/${envelopeId}/to/${vaultId}`,
          'POST'
        )
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Envelopes]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.VaultsList]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Vault]
        })
      }
    })

  const useUnscheduleEnvelope = () =>
    useMutation({
      mutationFn: (envelopeId: string) => {
        return handleApi<void, void>(
          `/ecm/envelopes/${envelopeId}/unschedule`,
          'POST'
        )
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Envelopes]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.VaultsList]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Vault]
        })
      }
    })

  return {
    useCreateEnvelope,
    useUpdateEnvelope,
    useRenameEnvelope,
    usePublishEnvelope,
    useGetEnvelope,
    usePreReviewEnvelope,
    useReviewEnvelope,
    useRequestEmailMFA,
    useRequestSmsMFA,
    useRequestWhatsAppMFA,
    useApproveEnvelope,
    useRejectEnvelope,
    useEnvelopeRestore,
    useEnvelopeOwners,
    useEnvelopesList,
    useEnvelopesListDraftOrHold,
    useEnvelopesListPublished,
    useEnvelopesListCancelled,
    useEnvelopesListExpired,
    useEnvelopesListSigned,
    useEnvelopesListPublishScheduled,
    useEnvelopesListRejected,
    useEnvelopesListCancelledBySignerMfaError,
    useEnvelopesListExpireSoon,
    useEnvelopesListPending,
    useEnvelopesListApprovedByMe,
    useEnvelopesListRejectedByMe,
    useDeleteEnvelope,
    useCancelEnvelope,
    useHoldEnvelope,
    useEnvelopeSignatureLink,
    useEnvelopeUnsubscribe,
    useMoveEnvelopeToVault,
    useUnscheduleEnvelope
  }
}

export default useEnvelopeApi
